import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import Hero from "Layout/Hero/Hero"
import MainLayout from "Layout/layout";
import Footer from "Layout/Footer/Footer";
import ContactForm from "Layout/Forms/ContactForm/ContactForm";
import MapSection from "Layout/MapSection/MapSection";
import config from "data/SiteConfig";

const Contact = (props) => {
  const { location, pageContext, data } = props;
  const { page, optionsPage } = data;

  const address = {
    lat: 48.143356,
    lng: 11.780688
  }

  return (
    <MainLayout location={location} pageContext={pageContext}>
      <Helmet
        meta={[
          {
            property: 'og:title',
            content: page.acf.meta_info_title,
          },
          {
            property: 'og:description',
            content: page.acf.meta_info_description,
          },
          {
            property: 'og:image',
            content: page.acf.social_share_image.image.localFile.childImageSharp.fluid.src,
          },
          {
            property: 'og:url',
            content: location.href,
          },
          {
            property: 'og:site_name',
            content: config.siteTitle,
          },
          {
            name: 'twitter:image:alt',
            content: page.acf.meta_info_description,
          }
        ]}
      />
      <Hero
        headline={page.acf.hero.headline}
        text={page.acf.hero.subheadline}
        openingTimes={optionsPage.edges[0].node.options.opening_times}
        waitingTime={optionsPage.edges[0].node.options.news.current_waiting_time}
      />
      <ContactForm />
      <MapSection
        headline={page.acf.map.headline}
        address={address}
        googleMapsApiKey={config.mapsApiKey}
      />
      <Footer />
    </MainLayout>
  );
}

export const query = graphql`
  query ContactPageQuery($id: String!) {
    page: wordpressPage(id: { eq: $id }) {
      title
      slug
      acf {
        hero {
          headline
          subheadline
        }
        map {
          headline
        }
        social_share_image {
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
    optionsPage: allWordpressAcfOptions {
      edges {
        node {
          options {
            opening_times {
              opening_times_headline
              opening_times_additional_notice
              opening_times {
                opening_time_item
              }
            }
            news {
              current_waiting_time
            }
          }
        }
      }
    }
  }
`;

export default Contact
