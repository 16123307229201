/* eslint-disable no-console */
import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from "Accessories/Button/Button";
import HeadlineAndText from "Accessories/HeadlineAndText/HeadlineAndText"
import cn from "classnames";

import "./ContactForm.scss"

const ContactForm = (props) => {

  const { headline, containerClasses, formClasses } = props;

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [tel, setTel] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState("");

  const submitToServer = async () => {
    try {
      const data = await fetch(`/.netlify/functions/mail`, {
        method: "POST",
        body: JSON.stringify({firstname, lastname, email, message, tel}),
        headers: {'Content-Type': 'application/json'}
      })
      setSubmitSuccess(true)
      return data
    }
    catch(err) {
      console.log('Error: ', err)
      setSubmitSuccess(false)
      return false
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    submitToServer()
  }

  return (
    <div className={cn("contact-form-section section container pt-4 pb-8", containerClasses)}>
      {
        headline && (
          <div className="row text-align-center">
            <div className="col-12 col-md-10 col-lg-8 mx-auto">
              <HeadlineAndText headline={headline} isSubHeadline />
            </div>
          </div>
        )
      }
      <div className="row justify-content-center">
        <div className="form-wrapper col-12 col-md-10">
          <form
            className={cn("d-flex flex-wrap p-1 rounded-border rounded-shadow justify-content-between bg-white", formClasses)}
            name="contact"
            onSubmit={handleSubmit}
          >
            <TextField
              required
              id="outlined-sur-name"
              label="Vorname"
              margin="normal"
              type="text"
              name="firstname"
              variant="outlined"
              className="material-input"
              onChange={(e) => setFirstname(e.target.value)}
            />

            <TextField
              required
              id="outlined-last-name"
              label="Nachname"
              margin="normal"
              type="text"
              name="lastname"
              variant="outlined"
              className="material-input"
              onChange={(e) => setLastname(e.target.value)}
            />

            <TextField
              required
              id="outlined-email-input"
              label="Email"
              type="email"
              name="email"
              autoComplete="email"
              margin="normal"
              variant="outlined"
              className="material-input material-input-email"
              onChange={(e) => setEmail(e.target.value)}
            />

            <TextField
              id="outlined-required"
              label="Telefonnummer (Optional)"
              autoComplete="phone"
              margin="normal"
              type="text"
              name="tel"
              variant="outlined"
              className="material-input"
              onChange={(e) => setTel(e.target.value)}
            />

            <TextField
              required
              id="outlined-multiline-static"
              label="Deine Nachricht"
              multiline
              rows="8"
              type="text"
              name="message"
              margin="normal"
              variant="outlined"
              className="material-input"
              onChange={(e) => setMessage(e.target.value)}
            />
            {
              submitSuccess ? (
                <>
                  <Button
                    className="btn-contact-submit text-white bg-green text-md px-1 mt-1 ml-auto"
                    realButton
                    text="✔"
                    disabled
                  />
                  <div className="d-flex col-12 submit-success mt-2">
                    <p className="ml-auto">Vielen Dank für ihre Anfrage!</p>
                  </div>
                </>
              ) : (
                <Button
                  className="btn-contact-submit mt-1 px-1 ml-auto"
                  realButton
                  text="Senden"
                />
              )
            }
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactForm