import React from "react";
import HeadlineAndText from "Accessories/HeadlineAndText/HeadlineAndText"
import GoogleMapReact from 'google-map-react';
import { ReactComponent as SvgIconMarker } from "static/assets/brand/icons/Juto_maps_1.svg";

import "./MapSection.scss";

const isClient = typeof window !== 'undefined';

const MapSection = (props) => {

  const {
    headline,
    address,
    googleMapsApiKey
  } = props;

  const lat = parseFloat(address.lat);
  const lng = parseFloat(address.lng);

  const MapMarker = () => <div className="map-marker"><SvgIconMarker lat={lat} lng={lng} /></div>;

  return (
    <div className="map-section on-white container-fluid pt-6 pt-md-4 position-relative pb-10">
      <div className="container position-relative">
        <div className="row text-align-center">
          <div className="col-12 col-md-10 col-lg-8 mx-auto">
            <HeadlineAndText headline={headline} isSubHeadline />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-10">
            <div className="map">
              { isClient && (
                <GoogleMapReact
                  bootstrapURLKeys={{ key: googleMapsApiKey }}
                  defaultCenter={[lat, lng]}
                  yesIWantToUseGoogleMapApiInternals
                  defaultZoom={14}
                >
                  <MapMarker
                    lat={lat}
                    lng={lng}
                    text="My Marker"
                  />
                </GoogleMapReact>
              )}
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default MapSection
